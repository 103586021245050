<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title color="white">Nuova richiesta</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <form @submit.prevent="creaRichiesta()">
                    <ion-list class="fields">
                        <!-- Tipologia -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">Tipologia <span class="required">*</span></div>
                                <div class="value">
                                    <ion-select
                                        name="tipologia"
                                        interface="action-sheet"
                                        cancel-text="Annulla"
                                        ok-text="Conferma"
                                        v-model="richiesta.tipologia"
                                        value="1"
                                        placeholder="Seleziona"
                                        @ionChange="toggleField($event.target.value)"
                                    >
                                        <ion-select-option
                                            v-for="tipologia in tipologie_richiesta"
                                            :key="tipologia.richieste_tipologia_id"
                                            :value="tipologia.richieste_tipologia_id"
                                        >
                                            {{ tipologia.richieste_tipologia_value }}
                                        </ion-select-option>
                                    </ion-select>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Sottotipologia -->
                        <ion-item v-if="showSottocategorie" lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Categoria
                                </div>
                                <div class="value">
                                    <ion-select
                                        name="sottotipologia"
                                        interface="action-sheet"
                                        cancel-text="Annulla"
                                        ok-text="Conferma"
                                        v-model="richiesta.sottotipologia"
                                        value="1"
                                        placeholder="Seleziona"
                                        @ionChange="setSottotipologia($event.target.value)"
                                    >
                                        <ion-select-option
                                            v-for="categoria in sottotipologie"
                                            :key="categoria.richieste_sottotipologia_id"
                                            :value="categoria.richieste_sottotipologia_id"
                                        >
                                            {{ categoria.richieste_sottotipologia_value }}
                                        </ion-select-option>
                                    </ion-select>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Data inizio -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">Data inizio <span class="required">*</span></div>
                                <div class="value">
                                    <ion-datetime
                                        name="date_start"
                                        display-format="DD/MM/YYYY"
                                        picker-format="DD/MM/YYYY"
                                        min="2021"
                                        max="2099"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="richiesta.data_inizio"
                                    ></ion-datetime>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Data fine -->
                        <ion-item v-if="showDataFine" lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">Data fine</div>
                                <div class="value">
                                    <ion-datetime
                                        name="date_end"
                                        display-format="DD/MM/YYYY"
                                        picker-format="DD/MM/YYYY"
                                        min="2021"
                                        max="2099"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="richiesta.data_fine"
                                        placeholder="gg/mm/aaaa"
                                    ></ion-datetime>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Ora inizio -->
                        <ion-item lines="none" class="ion-no-padding" v-show="showOrario">
                            <div class="field">
                                <div class="field_title">Ora inizio</div>
                                <div class="value">
                                    <ion-datetime
                                        name="start_time"
                                        display-format="HH:mm"
                                        picker-format="HH:mm"
                                        value="1990-02-19T07:43Z"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="richiesta.ora_inizio"
                                        placeholder="Seleziona ora di inizio"
                                    ></ion-datetime>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Ora fine -->
                        <ion-item lines="none" class="ion-no-padding" v-show="showOrario">
                            <div class="field">
                                <div class="field_title">Ora fine</div>
                                <div class="value">
                                    <ion-datetime
                                        name="end_time"
                                        display-format="HH:mm"
                                        picker-format="HH:mm"
                                        value="1990-02-19T07:43Z"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="richiesta.ora_fine"
                                        placeholder="Seleziona ora di fine"
                                    ></ion-datetime>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Protocollo INPS -->
                        <ion-item lines="none" class="fieion-no-padding" v-show="showProtocolloInps">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    Protocollo INPS
                                </div>
                                <div class="value">
                                    <ion-input type="text" v-model="richiesta.protocollo_inps" placeholder="Inserisci n° pratica INPS"></ion-input>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Note -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">Note</div>
                                <div class="value">
                                    <ion-textarea type="text" rows="3" v-model="richiesta.note" placeholder="Inserisci eventuali note delle richiesta">
                                    </ion-textarea>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Allegato -->
                        <ion-item lines="none" class="fieion-no-padding">
                            <div class="field">
                                <div class="section_title">Allegati</div>
                                <label for="file-upload" class="btn_select_allegato">
                                    <ion-icon :icon="attachOutline"></ion-icon>
                                </label>
                                <input id="file-upload" type="file" @change="selezionaAllegato($event)" />

                                <span v-if="currentFile" class="file_name">
                                    <div class="file_name_title">
                                        {{ currentFile.name }}
                                    </div>
                                    <div class="remove_allegato">
                                        <ion-icon :icon="trash" @click="removeAllegato()" class="icon_remove"></ion-icon>
                                    </div>
                                </span>
                            </div>
                        </ion-item>

                        <!-- Banca ore -->
                        <ion-item v-if="showBancaOre && showBancaOreSettings" lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">Vuoi usare le ore della banca ore?</div>
                                <div class="value">
                                    <ion-radio-group value="0" @ionChange="setUsaBancaOre($event)">
                                        <ion-item lines="none" class="ion-no-padding">
                                            <ion-label>No</ion-label>
                                            <ion-radio slot="start" value="0"></ion-radio>
                                        </ion-item>
                                        <ion-item lines="none" class="ion-no-padding">
                                            <ion-label>Sì</ion-label>
                                            <ion-radio slot="start" value="1"></ion-radio>
                                        </ion-item>
                                    </ion-radio-group>
                                </div>
                            </div>
                        </ion-item>
                    </ion-list>
                    <div class="actions">
                        <button type="submit" class="btn_richiesta">
                            Invia richiesta
                        </button>
                    </div>
                </form>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    modalController,
    IonList,
    IonItem,
    IonDatetime,
    IonTextarea,
    IonInput,
    IonSelect,
    IonSelectOption,
    IonIcon,
    IonRadioGroup,
    IonRadio,
    IonLabel,
} from "@ionic/vue";
import { arrowBackOutline, trash, attachOutline } from "ionicons/icons";

import { defineComponent, ref, reactive, watch, onMounted } from "vue";

import { LOCALSTORAGE_KEY } from "@/custom_config";

import moment from "moment";

import { openToast } from "@/services/toast";
import apiRichieste from "@/services/richieste";
import apiImpostazioniHr from "@/services/impostazioni_hr";

export default defineComponent({
    name: "ModalNuovaRichiesta",
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonButton,
        IonList,
        IonItem,
        IonDatetime,
        IonTextarea,
        IonInput,
        IonSelect,
        IonSelectOption,
        IonIcon,
        IonRadioGroup,
        IonRadio,
        IonLabel,
    },
    setup(props, context) {
        const start_date = new Date();
        const end_hour = new Date();
        const start_hour = new Date(end_hour);
        start_hour.setHours(start_hour.getHours() + 1);
        end_hour.setHours(start_hour.getHours() + 2);

        const userID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).users_id; //set userID in user field
        const dipendenteID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_id;

        const richiesta = reactive({
            user: "", //from logged user
            data_inizio: start_date.toISOString(),
            //data_fine: start_date.toISOString(),
            data_fine: "",
            /*ora_inizio: start_hour.toISOString(),
      ora_fine: end_hour.toISOString(),*/
            ora_inizio: "",
            ora_fine: "",
            tipologia: "",
            sottotipologia: "",
            protocollo_inps: "",
            note: "",
            usa_banca_ore: 0,
            allegato: null,
        });

        const tipologie_richiesta = ref([]);
        const sottotipologie = ref([]);
        const showSottocategorie = ref(true);

        const showOrario = ref(false);
        const showProtocolloInps = ref(false);
        const showBancaOre = ref(false);
        const showDataFine = ref(true);

        const successResponse = ref(false);
        const fieldFlag = ref(false); //se tutti i campi sono ok diventa true e posso procedere con la creazione

        const selectedTipologia = ref({
            richieste_tipologia_id: 1,
            richieste_tipologia_value: "Permesso",
        });

        const impostazioni_hr = ref([]);
        const showBancaOreSettings = ref(false);
        async function loadImpostazioniHr() {
            try {
                const res = await apiImpostazioniHr.getImpostazioniHr();
                if (res.status === 200 && res.data.status === 0 && res.data.data.length > 0) {
                    impostazioni_hr.value = res.data.data[0];
                    const settings = res.data.data[0];
                    if (settings.impostazioni_hr_banca_ore) {
                        //showBancaOreSettings.value = settings.impostazioni_hr_banca_ore;
                        showBancaOreSettings.value = settings.impostazioni_hr_banca_ore == "1" ? true : false;
                    }
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta delle impostazioni", "toast_danger");
            }
        }

        /**
         * ! Se data inizio > data fine aggiorno con data fine = data inizio
         */
        watch(
            () => richiesta.data_inizio,
            (date, prevDate) => {
                if (richiesta.data_fine) {
                    if (moment(richiesta.data_fine).isBefore(moment(richiesta.data_inizio))) {
                        richiesta.data_fine = date;
                    }
                }
            }
        );
        /**
         * ! Se ora inizio > ora fine aggiorno con ora fine = ora inizio
         */
        watch(
            () => richiesta.ora_inizio,
            (hour, prevHour) => {
                if (richiesta.ora_fine) {
                    if (moment(richiesta.ora_fine).isBefore(moment(richiesta.ora_inizio))) {
                        richiesta.ora_fine = hour;
                    }
                }
            }
        );
        /**
         * ! Se data fine < data inizio aggiorno con data inizio = data fine
         */
        watch(
            () => richiesta.data_fine,
            (date, prevDate) => {
                if (richiesta.data_inizio) {
                    if (moment(richiesta.data_fine).isBefore(moment(richiesta.data_inizio))) {
                        richiesta.data_inizio = date;
                    }
                }
            }
        );
        /**
         * ! Se ora fine < ora inizio aggiorno con ora inizio = ora iniziofine
         */
        watch(
            () => richiesta.ora_fine,
            (hour, prevHour) => {
                if (richiesta.ora_inizio) {
                    if (moment(richiesta.ora_fine).isBefore(moment(richiesta.ora_inizio))) {
                        richiesta.ora_inizio = hour;
                    }
                }
            }
        );

        /**
         * ! Close modal after form data submit
         */
        async function closeModalOnSubmit(successResponse, res) {
            if (successResponse.value) {
                await modalController.dismiss(res);
            } else {
                await modalController.dismiss();
            }
        }

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /**
         * ! Get sottotipologia for selected tipologia
         */
        async function getSottotipologie(tipoRichiesta) {
            apiRichieste
                .getSottotipologieRichieste(tipoRichiesta)
                .then((response) => {
                    //Se non ho sottocategorie svuoto quelle precedentemente ottenute,
                    //svuoto l'eventuale sottocategoria già scelta e nascondo la select
                    if (response.data.length === 0) {
                        sottotipologie.value = [];
                        showSottocategorie.value = false;
                    } else {
                        showSottocategorie.value = true;
                        sottotipologie.value = response.data;
                    }
                    richiesta.sottotipologia = "";
                })
                .catch((error) => {
                    error.value = true;
                    openToast("Errore durante la richiesta delle sottotipologie", "toast_danger");
                });
        }

        /**
         * ! Toggle field visibility based on richiesta tipologia
         */
        function toggleField(tipoRichiesta) {
            getSottotipologie(tipoRichiesta);
            //Permesso
            if (tipoRichiesta === "1") {
                showOrario.value = true;
                showBancaOre.value = true;
                showProtocolloInps.value = false;
                showDataFine.value = false;

                richiesta.ora_inizio = start_hour.toISOString();
                richiesta.ora_fine = end_hour.toISOString();
                richiesta.data_fine = start_date.toISOString();
            } else if (tipoRichiesta === "2") {
                //Ferie
                showOrario.value = false;
                showBancaOre.value = true;
                showProtocolloInps.value = false;
                showDataFine.value = true;

                richiesta.ora_inizio = "";
                richiesta.ora_fine = "";
                richiesta.data_fine = start_date.toISOString();
            } else if (tipoRichiesta === "3") {
                //Malattia
                showOrario.value = false;
                showProtocolloInps.value = true;
                showBancaOre.value = false;
                showDataFine.value = true;

                richiesta.ora_inizio = "";
                richiesta.ora_fine = "";
                richiesta.data_fine = "";
                richiesta.usa_banca_ore = "";
            } else if (tipoRichiesta === "4") {
                //Smart Working
                showOrario.value = false;
                showProtocolloInps.value = false;
                showBancaOre.value = false;
                showDataFine.value = false;

                richiesta.ora_inizio = "";
                richiesta.ora_fine = "";
                richiesta.data_fine = start_date.toISOString();
                richiesta.usa_banca_ore = "";
            } else if (tipoRichiesta === "5") {
                //Trasferta
                showOrario.value = true;
                showProtocolloInps.value = false;
                showBancaOre.value = false;
                showDataFine.value = false;

                richiesta.ora_inizio = start_hour.toISOString();
                richiesta.ora_fine = end_hour.toISOString();
                richiesta.data_fine = start_date.toISOString();
            } /* else {
                //Legge 104
                showOrario.value = false;
                showProtocolloInps.value = false;
                showBancaOre.value = false;

                richiesta.ora_inizio = "";
                richiesta.ora_fine = "";
                richiesta.data_fine = start_date.toISOString();
                richiesta.usa_banca_ore = "";
            } */
        }

        const currentFile = ref(null);
        function selezionaAllegato(event) {
            currentFile.value = event.target.files[0];
            richiesta.allegato = currentFile.value;
        }

        function removeAllegato() {
            currentFile.value = null;
        }

        /**
         * Set richiesta.usa_banca_ore value based on selected radio
         */
        function setUsaBancaOre(event) {
            richiesta.usa_banca_ore = event.target.value;
        }

        /**
         * ! Set selected sottotipologia
         */
        function setSottotipologia(sottotipologia_id) {
            richiesta.sottotipologia = sottotipologia_id;
        }

        /**
         * ! Create new richiesta
         */
        function creaRichiesta() {
            //Formattazione per salvataggio dati
            const data_inizio_formatted = moment(richiesta.data_inizio).format("YYYY-MM-DD");
            const data_fine_formatted = richiesta.data_fine != "" ? moment(richiesta.data_fine).format("YYYY-MM-DD") : "";
            const ora_inizio_formatted = richiesta.ora_inizio != "" ? moment(richiesta.ora_inizio).format("HH:mm") : "";
            const ora_fine_formatted = richiesta.ora_fine != "" ? moment(richiesta.ora_fine).format("HH:mm") : "";
            /*const data_ora_inizio = data_inizio_formatted + " " + ora_inizio_formatted;
            const data_ora_fine = data_fine_formatted + " " + ora_fine_formatted; */

            //Se non ho tipologia mostro errore
            if (!richiesta.tipologia) {
                openToast("La tipologia di richiesta è obbligatoria", "toast_danger");
            }

            //Formattazione per controlli
            const startDate = moment(richiesta.data_inizio);
            const endDate = moment(richiesta.data_inizio);
            const startTime = moment(richiesta.ora_inizio);
            const endTime = moment(richiesta.ora_fine);
            const todayDate = moment();
            /* console.log(startDate);
            console.log(endDate);
            console.log(startTime);
            console.log(endTime); */

            /**
             * * PERMESSO (1)
             * * Mostrare ora inizio e fine, data fine puà non esserci
             */
            if (richiesta.tipologia === "1") {
                //console.log("tipo richiesta: PERMESSO");
                const ora_inizio = moment(richiesta.ora_inizio).valueOf();
                const ora_fine = moment(richiesta.ora_fine).valueOf();
                const dataInizio = moment(richiesta.data_inizio).valueOf();
                const dataFine = moment(richiesta.data_fine).valueOf();

                if (startDate.isBefore(todayDate, "day")) {
                    openToast("Non puoi inserire una richiesta per una data precedente ad oggi", "toast_danger");
                } else if (startTime.isAfter(endTime)) {
                    openToast("L'ora inizio non può essere maggiore dell'ora fine", "toast_danger");
                } else if (startDate.isAfter(endDate, "day")) {
                    openToast("La data di inizio non può essere successiva alla data di fine", "toast_danger");
                } else {
                    fieldFlag.value = true;
                }
            }
            /**
             * * FERIE (2)
             * * Nascondere ora inzio e fine, controllare che non sia maggiore di data inizio
             */
            if (richiesta.tipologia === "2") {
                if (startDate.isBefore(todayDate, "day")) {
                    openToast("Non puoi inserire una richiesta per una data precedente ad oggi", "toast_danger");
                } else if (startDate.isAfter(endDate, "day")) {
                    openToast("La data di inizio non può essere successiva alla data di fine", "toast_danger");
                } else {
                    fieldFlag.value = true;
                }
            }
            /**
             * * MALATTIA (3)
             * * Nascondere ora inzio e fine, data fine può non esserci (se c'è deve essere maggiore di data inizio)
             */
            if (richiesta.tipologia === "3") {
                //console.log("tipo richiesta: MALATTIA");
                if (richiesta.data_fine != "") {
                    if (startDate.isBefore(todayDate, "day")) {
                        openToast("Non puoi inserire una richiesta per una data precedente ad oggi", "toast_danger");
                    } else if (startDate.isAfter(endDate, "day")) {
                        openToast("La data di inizio non può essere successiva alla data di fine", "toast_danger");
                    } else {
                        fieldFlag.value = true;
                    }
                } else {
                    fieldFlag.value = true;
                }
            }
            /**
             * * Smart working (4)
             * * Nascondere ora inzio e fine, data fine deve esserci
             */
            if (richiesta.tipologia === "4") {
                //console.log("tipo richiesta: Smart working");
                if (richiesta.data_fine != "") {
                    if (startDate.isBefore(todayDate, "day")) {
                        openToast("Non puoi inserire una richiesta per una data precedente ad oggi", "toast_danger");
                    } else if (startDate.isAfter(endDate, "day")) {
                        openToast("La data di inizio non può essere successiva alla data di fine", "toast_danger");
                    } else {
                        fieldFlag.value = true;
                    }
                } else {
                    fieldFlag.value = true;
                }
            }
            /**
             * * Trasferta (4)
             * * Nascondere ora inzio e fine, data fine deve esserci
             */
            if (richiesta.tipologia === "5") {
                //console.log("tipo richiesta: Trasferta");
                const ora_inizio = moment(richiesta.ora_inizio).valueOf();
                const ora_fine = moment(richiesta.ora_fine).valueOf();
                const dataInizio = moment(richiesta.data_inizio).valueOf();
                const dataFine = moment(richiesta.data_fine).valueOf();

                if (startDate.isBefore(todayDate, "day")) {
                    openToast("Non puoi inserire una richiesta per una data precedente ad oggi", "toast_danger");
                } else if (startTime.isAfter(endTime)) {
                    openToast("L'ora inizio non può essere maggiore dell'ora fine", "toast_danger");
                } else if (startDate.isAfter(endDate, "day")) {
                    openToast("La data di inizio non può essere successiva alla data di fine", "toast_danger");
                } else {
                    fieldFlag.value = true;
                }
            }

            if (fieldFlag.value) {
                const data = new FormData();
                data.append("richieste_user_id", dipendenteID); //logged user
                data.append("richieste_dal", data_inizio_formatted);
                data.append("richieste_al", data_fine_formatted);
                data.append("richieste_ora_inizio", ora_inizio_formatted);
                data.append("richieste_ora_fine", ora_fine_formatted);
                if (richiesta.sottotipologia) {
                    data.append("richieste_sottotipologia", richiesta.sottotipologia);
                }
                data.append("richieste_tipologia", richiesta.tipologia);
                data.append("richieste_protocollo_inps", richiesta.protocollo_inps);
                if (richiesta.note) {
                    data.append("richieste_note", richiesta.note);
                }
                //Malattia approvato automaticamente
                if (richiesta.tipologia === "3") {
                    data.append("richieste_stato", "2");
                } else {
                    data.append("richieste_stato", "1");
                }
                //Utilizzo banca ore (1 o 0 in base alla selezione, vuoto se no impostao un valore ma poi cambiato tipo in melattia o legge104)
                data.append("richieste_utilizzo_banca_ore", richiesta.usa_banca_ore);

                if (richiesta.allegato) {
                    data.append("richieste_allegato", richiesta.allegato);
                }

                // Display the values
                /* for (const value of data.entries()) {
                    console.log(value[0] + ", " + value[1]);
                }
                return; */

                apiRichieste
                    .saveRichiesta(data)
                    .then((response) => {
                        if (response.data.status == 8) {
                            openToast(response.data.message, "toast_danger");
                        } else {
                            const res = response.data.data[0];
                            successResponse.value = true;
                            closeModalOnSubmit(successResponse, res);
                        }
                    })
                    .catch((error) => {
                        //console.error(error);
                        openToast("Errore durante la creazione della richiesta", "toast_danger");
                    });
            }
        }

        onMounted(() => {
            loadImpostazioniHr();

            apiRichieste
                .getTipologiaRichiesta()
                .then((response) => {
                    tipologie_richiesta.value = response;
                })
                .catch((error) => {
                    error.value = true;
                    openToast("Errore durante la richiesta delle tipologie di richiesta effettuabili", "toast_danger");
                });
        });

        return {
            richiesta,
            creaRichiesta,
            closeModal,
            tipologie_richiesta,
            showOrario,
            showProtocolloInps,
            toggleField,
            arrowBackOutline,
            selectedTipologia,
            setUsaBancaOre,
            showBancaOre,
            showDataFine,
            //Sottotipologie
            sottotipologie,
            setSottotipologia,
            showSottocategorie,
            //Allegato
            currentFile,
            selezionaAllegato,
            removeAllegato,
            trash,
            // Banca ore
            showBancaOreSettings,
            attachOutline,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.page_content {
    width: 100%;
    position: relative;
    min-height: 100%;
    padding: 16px;
}

.fields {
    width: 100%;
    border-radius: 8px;
    /*padding: 10px;*/
    padding: 16px;
    /*box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);*/
    box-shadow: 0px 2px 5px 0px #00000040;
}
.field {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 8px;
}

.field_title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
    color: #000000;
}

ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --padding-left: 0;
    --padding-right: 0;
    --inner-padding-end: 0;
    --ripple-color: transparent;
    --background-activated: transparent;
    --background-activated-opacity: 0;
    margin-bottom: 4px;
}
ion-datetime,
ion-select {
    max-width: 100%;
    /*border: 1px solid #d1d5db;*/
    border: 1px solid #0000001a;
    border-radius: 6px;
    --padding-start: 12px;
    --padding-end: 12px;
    --padding-left: 12px;
    --padding-right: 12px;
    --placeholder-color: #00000080;
}
/* Set the icon color and opacity */
ion-select::part(icon) {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    opacity: 1;
    color: #1c90cf;
    /* background-color: #1c90cf;
    border-radius: 50%; */
}

ion-input,
ion-textarea {
    --placeholder-color: #00000080;
    --padding-start: 12px !important;
    --padding-end: 12px !important;
    --padding-left: 12px !important;
    --padding-right: 12px !important;
    /*border: 1px solid #d1d5db;*/
    border: 1px solid #0000001a;
    border-radius: 6px;
    margin-top: 0;
}

/* .btn_richiesta {
  --background: #16a34a;
  --background-activated: #15803d;
  --background-hover: #15803d;
  --background-focused: #15803d;
  --box-shadow: none;
  --border-radius: 4px;
  --padding-top: 10px;
  --padding-bottom: 10px;
  --padding-start: 10px;
  --padding-end: 10px;
  --ripple-color: none;
  --color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
} */

.actions {
    margin-top: 16px;
}
.btn_richiesta {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 8px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    background-color: #1c90cf;
    color: #ffffff;
}
ion-button {
    --color: #ffffff;
}

/**
* Allegato richiesta
*/
.btn_select_allegato {
    display: inline-block;
    width: 100%;
    padding: 8px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 4px;
    text-align: center;
    color: #086fa3;
    background: #e36f3026;
    /*box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);*/
}
.btn_select_allegato ion-icon {
    font-size: 16px;
    color: #ffffff;
    background: #e36f30;
    border-radius: 50%;
    padding: 4px;
}
input[type="file"] {
    display: none;
}
.file_name {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
}
.file_name_title {
    font-size: 12px;
    font-weight: 500;
}
.btn_carica_allegato {
    margin-top: 24px;
    display: inline-block;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    text-align: center;
    background-color: #1c90cf;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.icon_remove {
    /*color: rgb(239 68 68);*/
    background-color: #df000026;
    color: #c9372f;
    font-size: 12px;
    border-radius: 50%;
    padding: 4px;
}
.section_title {
    color: #1d1d1b;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
}

/** 28/03/2024 */
.required {
    color: #c70000;
}
</style>
