
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonSpinner,
    IonIcon,
    IonButton,
    modalController,
    IonButtons,
    menuController,
    onIonViewWillEnter,
} from "@ionic/vue";
import { add, refresh, menu } from "ionicons/icons";

import { ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";

import { LOCALSTORAGE_KEY } from "@/custom_config";

import moment from "moment";

import { openToast } from "@/services/toast";
import apiRichieste from "@/services/richieste";
import apiBancaOre from "@/services/banca_ore";
import apiImpostazioniHr from "@/services/impostazioni_hr";
import apiDipendenti from "@/services/dipendenti";

import ModalNuovaRichiesta from "@/components/richieste/ModalNuovaRichiesta.vue";
import RichiestaDetail from "@/components/richieste/RichiestaDetail.vue";
/* import { dbRichieste, getRichieste } from "../services/localbase"; */

import { useUserStore } from "@/store/userStore"; // Importa lo store

export default {
    name: "Richieste",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonSpinner,
        IonIcon,
        IonButton,
        IonButtons,
    },
    setup() {
        const router = useRouter();
        const loading = ref(false);
        const richieste = ref([]);
        //const userID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).users_id;
        //const dipendenteID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_id;

        const { user } = useUserStore(); // Utilizza lo store
        console.log(user);
        const dipendenteID = user.dipendenti_id;

        const openMenu = () => {
            menuController.open("app-menu");
        };

        const dataRichiesta = computed(() => {
            return (dataInizio, dataFine) => {
                const inizio = moment(dataInizio).format("DD/MM/YYYY");
                const fine = moment(dataFine).format("DD/MM/YYYY");
                if (!moment(dataFine).isValid()) {
                    return `Dal ${inizio}`;
                } else if (inizio == fine) {
                    return `${inizio}`;
                } else {
                    return `Dal ${inizio} al ${fine}`;
                }
            };
        });

        const orarioRichiesta = computed(() => {
            return (richiesta) => {
                if (richiesta.richieste_tipologia == 1 || richiesta.richieste_tipologia == 5) {
                    return `Dalle ${richiesta.richieste_ora_inizio} alle ${richiesta.richieste_ora_fine}`;
                }
            };
        });

        /*********************************************
         *
         * ! Dati dipendente per saldi aggiornati
         *
         ********************************************/
        const dipendente = ref(null);
        async function loadDipendente() {
            loading.value = true;
            try {
                const res = await apiDipendenti.getDipendente(dipendenteID);
                if (res.status === 200 && res.data.status === 0) {
                    dipendente.value = res.data.data;
                } else {
                    openToast("Si è verificato un durante il caricamento delle informazioni del dipendente", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Si è verificato un durante il caricamento delle informazioni del dipendente", "toast_danger");
            } finally {
                loading.value = false;
            }
        }

        /*********************************************
         *
         * ! Get all richieste
         *
         ********************************************/
        async function loadRichieste() {
            loading.value = true;
            try {
                richieste.value = await apiRichieste.getRichieste(dipendenteID);
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei dati", "toast_danger");
            }
            loading.value = false;
        }
        loadRichieste();

        /*********************************************
         *
         * ! Get all banca ora records
         *
         ********************************************/
        const banca_ore = ref([]);
        async function loadBancaOre() {
            loading.value = true;
            try {
                banca_ore.value = await apiBancaOre.getOre(dipendenteID);
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta delle ore disponibili", "toast_danger");
            }
            loading.value = false;
        }
        loadBancaOre();

        /*********************************************
         *
         * ! Saldi ratei ferie e permessi
         *
         ********************************************/
        const saldo_richieste = ref(null);
        const loadingRatei = ref(false);
        async function loadRatei() {
            loadingRatei.value = true;
            try {
                const res = await apiRichieste.getRatei(dipendenteID);
                if (res.status === 200 && res.data.status === 0) {
                    saldo_richieste.value = res.data.data[0];
                } else {
                    openToast("Errore durante la richiesta dei saldi ferie/permessi", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei saldi ferie/permessi", "toast_danger");
            } finally {
                loadingRatei.value = false;
            }
        }
        loadRatei();

        /*******************************************
         *
         * ! Impostazioni modulo HR
         *
         *******************************************/
        const impostazioni_hr = ref([]);
        const showBancaOre = ref(false);
        async function loadImpostazioniHr() {
            try {
                const res = await apiImpostazioniHr.getImpostazioniHr();
                if (res.status === 0 && res.data.length > 0) {
                    impostazioni_hr.value = res.data[0];
                    const settings = res.data[0];
                    if (settings.impostazioni_hr_banca_ore) {
                        //showBancaOre.value = settings.impostazioni_hr_banca_ore;
                        showBancaOre.value = settings.impostazioni_hr_banca_ore == "1" ? true : false;
                    }
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta delle impostazioni", "toast_danger");
            }
        }
        loadImpostazioniHr();

        /*********************************************
         *
         * ! Calculate banca ore balance
         *
         ********************************************/
        const calcolaSaldo = computed(() => {
            let balance = 0;
            banca_ore.value.forEach((element) => {
                //onsole.log(element);
                switch (element.banca_ore_movimento) {
                    case "1": //Aggiunto
                        balance += parseFloat(element.banca_ore_hours);
                        break;
                    case "2": ///Cancellata
                        balance += 0;
                        break;
                    case "3": //Pagata
                    case "4": //Utilizzata
                        balance -= parseFloat(element.banca_ore_hours);
                        break;
                    default:
                        balance = 0;
                        break;
                }
            });

            return balance.toFixed(2);
        });

        /*********************************************
         *
         * ! Open modal to create new presenza
         *
         ********************************************/
        async function openModal() {
            const modal = await modalController.create({
                component: ModalNuovaRichiesta,
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    richieste.value = [detail.data, ...richieste.value];
                    openToast("La richiesta è stata inserita con successo", "toast_success");
                }
            });
            return modal.present();
        }

        //Set correct background for richieste status
        const statoRichiesta = computed(() => {
            return (statusId) => {
                let className = "";
                if (statusId == 1) {
                    //attesa approvazione
                    className = "badge_warning";
                } else if (statusId == 2) {
                    //approvato
                    className = "badge_success";
                } else if (statusId == 3) {
                    //rifiutato
                    className = "badge_danger";
                }
                return className;
            };
        });

        //Set correct background for richieste status
        const setBackground = computed(() => {
            return (statusId) => {
                let className = "";
                if (statusId == 1) {
                    //permesso
                    className = "permesso";
                } else if (statusId == 2) {
                    //ferie
                    className = "ferie";
                } else if (statusId == 3) {
                    //malattia
                    className = "malattia";
                } else if (statusId == 4) {
                    //smart working
                    className = "smart_working";
                } else if (statusId == 5) {
                    //trasferta
                    className = "trasferta";
                }
                return className;
            };
        });

        /*********************************************
         *
         * ! Passing id to retrive detail in RichiestaDetail page
         *
         ********************************************/
        async function openRichiestaDetail(richiestaDetail) {
            const modal = await modalController.create({
                component: RichiestaDetail,
                componentProps: {
                    data: richiestaDetail,
                },
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    richieste.value = [detail.data, ...richieste.value];
                    openToast("Nuova richiesta inserita", "toast_success");
                }
            });
            return modal.present();
        }
        /* function openDetail(id: string) {
            router.push(`tab3/${id}`);
        } */

        /* Ad ogni accesso alla pagina richiedo i dati della bbanca ore perchè potrebbe essere cambiato il saldo */
        onIonViewWillEnter(() => {
            loadBancaOre();
            loadDipendente();
        });

        return {
            loading,
            add,
            refresh,
            //openDetail,
            //getRichieste,
            openModal,
            richieste,
            statoRichiesta,
            //New field
            loadRichieste,
            openRichiestaDetail,
            dataRichiesta,
            openMenu,
            menu,
            orarioRichiesta,
            calcolaSaldo,
            showBancaOre,
            saldo_richieste,
            loadingRatei,
            setBackground,
            dipendente,
        };
    },
};
